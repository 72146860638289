<template>
  <v-container>
    <!-- Type selector -->
    <v-row
      class="mb-2"
      align="center"
      justify="center"
    >
      <v-col
        cols="auto"
        order="0"
      >
        <v-btn
          icon
          @click="prev"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        order="1"
        class="px-0"
      >
        <v-btn
          color="primary"
          small
          @click="today"
        >
          <v-icon> mdi-calendar-today </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        order="2"
      >
        <v-btn
          icon
          @click="next"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <v-col
        :cols="largeScreen ? null : 12"
        :order="largeScreen ? 3 : 4"
        class="text-center text-h6"
      >
        <span v-if="$refs.calendar">
          {{ $refs.calendar.title | capitalize }}
        </span>
      </v-col>
      <v-col
        cols="auto"
        :order="largeScreen ? 4 : 3"
      >
        <v-btn-toggle
          v-model="typeId"
          mandatory
        >
          <v-btn
            v-for="(t, i) in types"
            :key="i"
            small
          >
            {{ t.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <!-- Calendar -->
    <v-sheet height="600">
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <v-calendar
        ref="calendar"
        v-model="focusValue"
        color="primary"
        :events="getEvents"
        :type="type"
        :interval-format="(locale, _) => locale.time"
        event-overlap-mode="stack"
        event-overlap-threshold="30"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
      >
        <template
          v-if="ready && type != 'month'"
          v-slot:day-body="{ date, week }"
        >
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY() }"
          />
        </template>
      </v-calendar>
      <EventModal
        v-model="eventOpen"
        :event_id="selectedEvent._id"
        @refresh="$emit('refresh')"
      />
      <v-dialog
        v-model="eventOpenTiny"
        max-width="1100"
      >
        <v-card>
          <v-card-title>
            <span>
              {{ selectedEvent.name }}
            </span>
            <span
              v-if="selectedEvent.group"
              class="ml-auto mr-5"
            >
              {{ selectedEvent.group.name }}
            </span>
          </v-card-title>
          <v-card-text>
            <Summary
              :event="selectedEvent"
              class="pb-3"
            />
            <v-row
              v-if="canSeeFop"
              justify="end"
            >
              <v-col cols="auto">
                <v-btn
                  outlined
                  color="primary"
                  @click="seeFOP()"
                >
                  Voir la FOP associée
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-sheet>
  </v-container>
</template>

<script>
import EventModal from "../Event/EventModal";
import Summary from "@/components/Fop/Summary";

export default {
  name: "CalendarComponent",
  components: { EventModal, Summary },
  props: {
    events: Array,
    focus: String,
    loading: Boolean,
  },
  data() {
    return {
      typeId: 2,
      ready: false,
      selectedEvent: {},
      selectedElement: null,
      eventOpen: false, // Full window
      eventOpenTiny: false, // Tiny window (can't see details)
    };
  },
  computed: {
    getEvents() {
      return this.events.map((event) => {
        // Add v-calendar specific props
        if (!event["name"]) event["private"] = true;
        event["name"] = event["name"] || "Évènement privé";
        event["start"] = new Date(event.event_details.begin_date);
        event["end"] = new Date(event.event_details.end_date);
        event["color"] = this.colorEvent(event);
        event["timed"] = true;
        return event;
      });
    },
    canSeeFop() {
      return this.$store.getters.getShowFopsTab;
    },
    type() {
      return (this.types[this.typeId] || "4day").value;
    },
    // ---- for red line ----
    cal() {
      return this.$refs.calendar;
    },
    types() {
      if (this.largeScreen) {
        return [
          { text: "Jour", value: "day" },
          { text: "Semaine", value: "week" },
          { text: "Mois", value: "month" },
        ];
      } else {
        return [
          { text: "Jour", value: "day" },
          { text: "4-Jours", value: "4day" },
        ];
      }
    },
    focusValue: {
      get() {
        return this.focus;
      },
      set(x) {
        this.$emit("update:focus", x);
      },
    },
    largeScreen() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  watch: {
    typeId() {
      this.$nextTick(() => {
        this.scrollToTime();
      });
    },
  },
  mounted() {
    // ---- for red line ----
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    today() {
      this.focusValue = "";
    },
    viewDay({ date }) {
      this.focusValue = date;
      this.typeId = 0;
    },
    showEvent({ event }) {
      if (event.private) return;
      this.selectedEvent = event;
      if (event.event_details.can_see_details) {
        this.eventOpen = true;
      } else {
        this.eventOpenTiny = true;
      }
    },
    // ---- for red line ----
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    nowY() {
      let offset = this.cal.timeToY(this.cal.times.now);
      return offset ? offset + "px" : "-10px";
    },
    colorEvent(event) {
      return {
        progress: "grey",
        consent: "green",
        no_consent: "error",
        suspended: "orange",
        nofop: "primary",
        cancelled: "black",
      }[event.fop.status];
    },
    seeFOP() {
      this.$router.push("/myfops?id=" + this.selectedEvent._id);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>