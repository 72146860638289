<template>
  <v-card flat>
    <v-card-title
      class="mb-2 text-h4"
      style="word-break: keep-all"
    >
      Calendrier
    </v-card-title>
    <v-card-subtitle class="mb-3">
      Calendrier dynamique des évènements auquel vous avez accès en lecture.
    </v-card-subtitle>
    <template v-if="!loading">
      <v-card-actions class="mx-4 mb-0 pb-0">
        <v-row>
          <v-col :cols="locMode ? 6 : 12">
            <v-select
              v-model="mode"
              :items="formattedModes"
              label="Type de Calendrier"
              hide-details="auto"
              outlined
            />
          </v-col>
          <v-col
            v-if="locMode"
            :cols="6"
          >
            <InputSearch
              v-model="locations"
              type="location"
              outlined
              multiple
              :text="(x) => x.name"
              label="Lieux"
              hint="Vous pouvez saisir plusieurs lieux"
              chips
              single-line
            />
          </v-col>
        </v-row>
      </v-card-actions>
      <CalendarComponent
        :events="events"
        :focus.sync="focus"
        :loading="calendarLoading"
        @refresh="updateEvents()"
      />
      <TimelineHelp calendar />
      <template v-if="locMode">
        <v-sheet
          width="90%"
          class="mx-auto"
        >
          <v-row>
            <v-col>
              <v-card-text>
                *Vous voyez ici tous les évènements, même ceux que vous ne
                pouvez pas voir. Les évènements privés vous sont donc masqués.
              </v-card-text>
            </v-col>
          </v-row>
        </v-sheet>
      </template>
      <v-card-subtitle class="m-0 ml-2">
        <h3>Lien de synchronisation</h3>
        Ce lien permet de synchroniser vos événements Saturne avec votre
        calendrier préféré (Outlook / Google Calendar...). Vous pouvez suivre le
        <a
          href="https://markdown.data-ensta.fr/s/synchronisation-zemail-synapses-smartphone"
          target="_blank"
        >tuto</a>
        data sur la synchronisation du calendrier Synpases, le fonctionnement
        est le même.<br>
        <b>
          Veuillez noter que le calendrier Outlook est restreint dans le passé à
          1 mois après la fin des évènements.
        </b>
      </v-card-subtitle>
      <v-card-text class="mt-n2 ml-2">
        <v-text-field
          v-model="calendarUrl"
          class="url"
          readonly
          :append-icon="copyIcon"
          @click:append="copyUrl"
        />
      </v-card-text>
    </template>
    <v-progress-circular
      v-else
      class="d-block mx-auto"
      indeterminate
    />
  </v-card>
</template>

<script>
import CalendarComponent from "@/components/Utils/Calendar";
import InputSearch from "@/components/Inputs/InputSearch";
import TimelineHelp from "@/components/Utils/TimelineHelp";

export default {
  name: "Calendar",
  components: { CalendarComponent, InputSearch, TimelineHelp },
  data() {
    return {
      loading: true,
      calendarLoading: true,
      mode: "forUser",
      modes: [
        { forUser: "Mes évènements" },
        { byLocation: "Évènements par lieu" },
      ],
      events: [],
      locations: [],
      eventsForLocation: [],
      focus: "",
      calendarUrl: undefined,
      copyState: "",
    };
  },

  computed: {
    formattedModes() {
      return this.modes.map((x) => ({
        text: Object.values(x)[0],
        value: Object.keys(x)[0],
      }));
    },
    locMode() {
      return this.mode == "byLocation";
    },
    copyIcon() {
      switch (this.copyState) {
        case "loading":
          return "mdi-loading";
        case "copied":
          return "mdi-clipboard-check";
        case "error":
          return "mdi-clipboard-remove";
        default:
          return "mdi-content-copy";
      }
    },
    firstOfTheMonth() {
      let _d = new Date(this.focus || Date.now()),
        y = _d.getFullYear(),
        m = _d.getMonth();
      return new Date(y, m, 1).getTime();
    },
  },
  watch: {
    locations: {
      deep: true,
      handler() {
        this.updateEvents();
      },
    },
    locMode(v) {
      if (!v) this.locations = [];
      this.updateEvents();
    },
    focus() {
      this.updateEvents();
    },
  },
  created() {
    this.updateEvents();
  },
  methods: {
    updateEvents() {
      this.calendarLoading = true;
      if (!this.locMode) {
        // Normal events
        this.axiosPost(process.env.VUE_APP_API_URL + "/calendar/events", {
          focus: this.firstOfTheMonth,
        }).then((res) => {
          this.loading = false;
          this.calendarLoading = false;
          this.events = res.data.events;
          this.calendarUrl = res.data.calendarURL;
        });
      } else {
        return this.axiosPost(
          process.env.VUE_APP_API_URL + "/calendar/locations",
          { query: this.locations }
        ).then((res) => {
          this.events = res.data;
          this.calendarLoading = false;
        });
      }
    },
    async copyUrl() {
      try {
        this.copyState = "loading";
        await navigator.clipboard.writeText(this.calendarUrl);
        this.copyState = "copied";
      } catch ($e) {
        this.copyState = "error";
        console.log($e);
      }
    },
  },
};
</script>

<style scoped>
.url {
  max-width: 650px;
}
</style>